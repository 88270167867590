import React from 'react';
import { Link } from 'gatsby';
import ContentPageWrapper from '../containers/Charity/ChapterGeneral/contentPageWrapper';
import SEO from '../components/seo';
import imageChapter14 from '../common/src/assets/image/charity/specter/kapitola_14_small.jpg';

export default () => {
  return (
    <ContentPageWrapper>
      <SEO title="Štrnásta kapitola: Populárna kultúra – bezuzdný hedonizmus | Ako duch komunizmu vládne nášmu svetu"
           description="Štrnásta kapitola: Populárna kultúra – bezuzdný hedonizmus"
           image={imageChapter14}/>
        <h1>Štrnásta kapitola: Populárna kultúra – bezuzdný hedonizmus</h1>
        <p>Boh stvoril ľudstvo a v priebehu dlhého trvania dejín pre neho ustanovil ortodoxnú kultúru, podľa ktorej by ľudstvo malo žiť. Hoci národy vo svete sa líšia, zdieľajú nápadne podobné základné hodnoty. Všetky etnické skupiny na Východe i na Západe zdôrazňujú cnosti ako sú úprimnosť, láskavosť, štedrosť, spravodlivosť, umiernenosť, pokora, odvaha, nezištnosť a podobne. To všetko sú cnosti, ktorým každý národ vždy vzdával hold a ktorým prostredníctvom svojich klasických diel učil svojich potomkov. Pre všetky etnické skupiny je tiež typické, že prejavujú úctu nebesiam a&nbsp;dodržiavajú nebeský zákon – pretože práve nebesá ľuďom odovzdali kultúru a kódex správania, ktoré by si mali osvojiť a udržiavať. Takto tiež vznikli univerzálne hodnoty.</p>
        <p>Otcovia – zakladatelia Spojených štátov prikladali morálke a&nbsp;etike veľkú dôležitosť. George Washington v ranej mladosti ručne prepísal <em>110 zásad zdvorilosti a slušného správania v&nbsp;spoločnosti a v konverzácii</em> (110 Rules of Civility &amp; Decent Behavior in Company and Conversation), ktoré boli založené na pravidlách vytvorených francúzskymi jezuitmi v roku 1595.<a href="#_edn1" id="_ednref1">[1]</a>&nbsp; Aj keď sa niektoré špecifiká môžu časom meniť, pravidlá zahŕňajú mnoho univerzálnych princípov: napríklad že človek musí byť úctivý, keď hovorí o&nbsp;Bohu a súvisiacich veciach, že musí jednať s ostatnými s úctou, byť skromný, ochraňovať verejné mravy, nezraňovať city a&nbsp;záujmy druhých, za všetkých okolností sa slušne správať, vkusne sa obliekať a uchovávať si dobré spôsoby, nerobiť niečo z pomsty, neohovárať iných za ich chrbtom, učiť sa od múdrych a spravodlivých, načúvať svojmu svedomiu a tak ďalej.</p>
        <p>Podobne, medzi trinásť cností Benjamina Franklina patrila zdržanlivosť, ticho, poriadok, odhodlanosť, šetrnosť, usilovnosť, úprimnosť, spravodlivosť, umiernenosť, čistota, pokoj, cudnosť a pokora.<a href="#_edn2" id="_ednref2">[2]</a></p>
        <p>Pred rokom 1950 mravné normy väčšiny ľudí spĺňali všeobecne uznávaný štandard. Ľudia na Východe i na Západe si zachovávali mnoho tradícií a zvykov, ktoré by ľudstvo malo mať. Hoci Čínska komunistická strana (ČKS) začala v týchto rokoch ničiť kultúrne dedičstvo a morálku starej Číny, Číňania si napriek tomu uchovali mnoho tradičných cností, ktoré prevládali predtým, než si strana uzurpovala moc.</p>
        <p>Spolu s tým, ako sa moc a vplyv komunizmu rozrastal, a&nbsp;zvlášť po 60. rokoch minulého storočia, sa však ľudia ako na Východe, tak aj na Západe, čoraz častejšie vydávali cestou morálneho úpadku.</p>
        <p>ČKS začala v Číne v roku 1966 kultúrnu revolúciu a odštartovala desaťročnú kampaň na odstránenie „štyroch prežitkov“ (tzn. starého myslenia, starej kultúry, starých obyčajov a starých zvykov). Túto kampaň čoskoro nasledovalo intenzívne hnutie kontrakultúry v USA a ďalšie hnutia namierené proti tradíciám v&nbsp;ďalších častiach sveta. Všetko to boli celosvetové udalosti, ku ktorým došlo za rovnakým účelom zničenia tradície a privedenia ľudstva k&nbsp;morálnemu úpadku, ktorý si stanovil duch komunizmu.</p>
        <p>Tieto politické a kultúrne hnutia zanechali na dnešnom svete hlboké jazvy. Tradičné kultúrne základy čínskej spoločnosti boli úplne zničené a jej morálka začala rýchlo upadať. V západnej spoločnosti sa rozšírilo užívanie drog, sexuálna sloboda a promiskuita, rokenrol, kultúra hippies a duchovná prázdnota. To všetko vážne poškodilo základy západnej tradície.</p>
        <p>Potom, čo si mladí radikáli kontrakultúry uvedomili, že majú vplyv na spoločnosť, pokračovali vo svojom hnutí ďalšími prostriedkami. Začalo sa propagovať avantgardné umenie a literatúra, moderná ideológia a ďalšie odchýlené koncepty. S pomocou technológií ako sú internet, mobilné telefóny a najrôznejšie masovokomunikačné prostriedky, sa celá ľudská rasa rýchlo odklonila od tradičnej kultúry a spôsobu života a smeruje tak do priepasti mravného úpadku a skazenosti.</p>
        <p>Ak sa pozrieme na súčasný svet, miera úpadku ľudskej morálky a takmer všetkých aspektov populárnej kultúry a spoločenského života je priam šokujúca. Potom čo ČKS prostredníctvom neustálych politických kampaní zničila hlbokú, tradičnú čínsku kultúru, vytvorila zlovoľný systém straníckej kultúry. Mladá generácia, ktorá v tejto kultúre vyrástla, nevie nič o tradičnej kultúre inšpirovanej nebesami. Môžeme povedať, že s výnimkou niektorých segmentov spoločnosti, ktoré sa ešte držia tradícií a odolávajú pokušeniu a rozličným návnadám, sa sotva dá nazvať preháňaním, keď povieme, že komunizmu sa takmer podarilo dosiahnuť svoj cieľ – zničiť ľudskú kultúru na celom svete.</p>
        <h2>1. Kultúra komunistickej strany</h2>
        <p>Potom, čo sa začala Čína v rámci reforiem v 80. rokoch otvárať, Číňania, ktorí cestovali do zahraničia, šokovali občanov tamojších krajín svojím správaním. Dovtedy mali mnohí obyvatelia Západu predstavu o tradičných Číňanoch ako o jemných, zdvorilých, skromných, pracovitých a láskavých ľuďoch.</p>
        <p>Avšak po desaťročiach vymývania mozgov a&nbsp;transformácie komunistickou stranou sa Číňania úplne zmenili. Začali byť neohrabaní a hluční. Predbiehali sa v radoch a na verejnosti nebrali ohľad na druhých. Fajčili pred tabuľami so zákazom fajčenia. Obliekali sa nepekne, pľuli na zem a hádzali okolo seba odpadky. Často tiež zneužívali pohostinnosť a láskavosť druhých.</p>
        <p>V súčasnosti sa správanie niektorých čínskych turistov ešte zhoršilo. Títo turisti sa šplhajú na kultúrne a historické pamiatky a poškodzujú ich, nechávajú svoje deti vykonávať potrebu na verejnosti, nesplachujú po sebe toaletu, berú si výrobky, ktoré sú zadarmo, plytvajú jedlom v samoobslužných jedálňach, vyvolávajú hádky kvôli každej maličkosti a vytvárajú problémy na letiskách, čím spôsobujú meškania letov.</p>
        <p>Čo sa to s Číňanmi stalo?</p>
        <p>Odpoveď je veľmi jednoduchá. ČKS zničila tradičnú čínsku kultúru a nahradila ju kultúrou komunistickej strany, čo je dôležitou súčasťou plánu ku skazeniu ľudstva.</p>
        <p>Pojem „stranícka kultúra“ sa týka spôsobov myslenia, reči i&nbsp;správania &nbsp;vyplývajúcich z pôsobenia prvkov komunistickej strany. Možno ich zhrnúť ako podvádzanie, zlo a boj. Vedúcou ideológiou straníckej kultúry je ateizmus a materializmus. Strana túto ideológiu vštepuje ľuďom spolu s komunistickými konceptmi, ktoré zahŕňajú všemožné prvky odchýlenej kultúry, filozofiu boja a najhoršie aspekty dávnej doby v novom šate. Čínska komunistická strana efektívne využívala stranícku kultúru na to, aby premenila myslenie čínskych ľudí.</p>
        <p>Tí, ktorí presadzovali tradičnú morálku, civilizáciu a správanie, boli počas proletárskej revolúcie ČKS označení nálepkou „vykorisťujúca trieda“. Komunistická strana vyhlásila zvyky proletariátu (pracujúcej triedy) za revolučné a dobré, a vyzvala čínskych intelektuálov, aby sa váľali v blate a nechali si na rukách narásť mozole. Vši na tele strana nazývala „revolučnými chrobákmi“. Od vodcov strany až k bežným kádrom, všetci boli hrdí na vulgárny jazyk, pretože preukazoval ich triedne uvedomenie, oddanosť revolúcii a údajnú blízkosť k&nbsp; masám.</p>
        <p>Strana tak núti ľudí opustiť všetko, čo je elegantné a civilizované, a namiesto toho im vnucuje drsný životný štýl proletárskych chuligánov. Krajina s takou dlhou históriou, preslávená svojimi dobrými spôsobmi tak poklesla do stavu zmätku, kde každý súťaží o slávu a bohatstvo. Strana urobila z Číny výstavnú sieň vulgárnej komunistickej kultúry. Pod kontrolou a infiltráciou straníckej kultúry sa zvrhli všetky oblasti kultúrneho života, vrátane literatúry, umenia a vzdelania.</p>
        <p>Strana chce bojovať s nebom, zemou a ľudstvom. Vnukla ľuďom súbor pokrivených štandardov dobra a zla a deformuje ich myslenie. Táto indoktrinácia je podporená štátnym násilím. „Poddaní“ strany sú potom nepostrehnuteľne ovplyvnení tým, čo okolo seba od narodenia neustále vidia a počujú, pretože strana monopolizuje všetky sociálne zdroje. Nepretržite besniaci propagandistický aparát núti ľudí čítať diela komunistických vodcov, zatiaľ čo kultúrna elita je nabádaná k produkcii učebníc, literatúry, filmu či správ a&nbsp;podobne – občanom sa všetkými prostriedkami vštepuje stranícka kultúra.</p>
        <p>Trvalo len niekoľko desaťročí, než komunizmus prinútil Číňanov uvažovať myšlienkami komunistickej strany, donútil ich hovoriť jazykom strany, prestať veriť v nebesá, konať bez ohľadu na dôsledky a odvážiť sa urobiť takmer čokoľvek. Človek sa stretne s podvodom takmer v každej sfére medziľudských kontaktov a&nbsp;neexistujú žiadne zábrany v správaní. Stranícka „zombie“ reč a&nbsp;rozmer jej ošúchaných klamstiev je ohromujúci.</p>
        <p>Dnešný čínsky národ sa kvôli straníckej kultúre ďaleko odklonil od univerzálnych hodnôt. Myslenie, názory a správanie boli hlboko zmenené a sú nesmierne pokrivené. Rodinné, spoločenské, vzdelávacie a pracovné vzťahy sú v Číne nenormálne a&nbsp;spôsoby Číňanov sú často nezlučiteľné s uvažovaním ľudí z&nbsp;nekomunistických spoločností, pre ktorých je takéto správanie len ťažko pochopiteľné.</p>
        <p>Počas kultúrnej revolúcie túto stranícku kultúru úplne prevzali Červené gardy a od tej doby svoje zlé návyky odovzdávajú mladším generáciám. Deti a mladí ľudia vychovávaní v straníckej kultúre sú prefíkaní a&nbsp;až príliš šikovní. Už v útlom veku vedia o všetkom zlom.</p>
        <p>Mladšej generácii chýba duchovná viera a je nemorálna a arogantná. Keď týchto mladých ľudí niekto provokuje alebo nahnevá, sú náchylní reagovať s iracionálnou brutalitou. Sexuálna zdržanlivosť a&nbsp;morálka sa zrútili. S tým, ako dnešní Číňania stratili svoje tradičné korene, osvojili si všetky najhoršie prvky kontrakultúrneho hnutia zo Západu.</p>
        <h3>Prejavy komunistickej straníckej kultúry</h3>
        <p>ČKS teraz hovorí o obnove tradičnej kultúry, ale to čo obnovuje nie je pravá tradičná kultúra. Je to len stranícka kultúra s tradičnou fasádou, ktorej však chýba najdôležitejší aspekt čínskej tradície – viera v nebesá.</p>
        <p>Pod vplyvom tejto straníckej kultúry ľudia dokonca využívajú takzvaných bohov, aby zbohatli. „Babičkin chrám“ v provincii Che-pej je veľmi populárny a hovorí sa, že ľudia tam môžu nájsť akéhokoľvek „boha“, ktorého si želajú uctievať. Ak chcete byť štátnym úradníkom, je tu k dispozícii „úradnícky boh“. Rovnako tak majú „boha bohatstva“, ktorý je zložený z bankoviek, „boha štúdia“ a dokonca aj „boha automobilov“, ktorý drží volant. Správca Babičkinho chrámu sa chváli: „Keď vám nejaký boh chýba, jednoducho si ho vyrobte.“<a href="#_edn3" id="_ednref3">[3]</a></p>
        <p>Skutočnú tradičnú kultúru nie je možné oživiť bez toho, aby sme odstránili morálnu korupciu vytvorenú čínskou komunistickou stranou. Hoci mnoho moderných literárnych a umeleckých diel ponúka prerozprávané staroveké príbehy, obsah je znečistený modernými myšlienkami. Herci sa síce obliekajú tradične, ale zaoberajú sa súčasnou drámou. Tradičná kultúra sa tak stala len fasádou a jej skutočný význam zostáva skrytý. V posledných rokoch sú napríklad populárne adaptácie drám z palácov cisárskej Číny, avšak všetky sa točia okolo žiarlivosti a intríg – zobrazujú boj a nenávisť, ktoré sú skôr rysmi komunizmu než odrazom historických skutočností.</p>
        <p>Voľná ​​adaptácia románu Putovanie na západ dokonca zobrazuje Sun Wu-Kchunga (Opičieho kráľa), ako sa objíma a&nbsp;necháva zviesť démonmi, ktorých v klasickom románovom spracovaní premohol. Ešte desivejšie je to, že mnoho ľudí, najmä tých mladých, ktorí o tradičnej čínskej kultúre nič nevedia, to z nejakého dôvodu považuje za tradičné alebo blízke tradícii. Ide o dôsledok toho, že strana rozvrátila nebesami inšpirovanú pravú čínsku kultúru a už celé desaťročia takto indoktrinuje verejnosť. Číňania si dnes myslia, že étos boja je niečo tradičného a že umenie, literatúra a dráma, cez ktoré prenikajú stranícke myšlienky, hoci prezlečené do tradičného kabáta, sú ozajstné.</p>
        <p>Stranícka kultúra ničí vieru v nebesá a nahradzuje ju ateizmom. Najpriamejším dôsledkom toho je strata dôvery v spoločnosť: podvody, falzifikáty, toxické potraviny, korupcia a podobne, to všetko sú dnes bežné javy. Typickým príkladom tejto krízy poctivosti je takzvaná „kultúra šan-čaj“, čo je pomenovanie pre falšovanie známych, najmä zahraničných výrobkov alebo značiek. Nejde o nič iné než o krádež a podvod. Tento pojem sa stal tak známym, že ho Oxfordský čínsko-anglický slovník uvádza ako neologizmus.<a href="#_edn4" id="_ednref4">[4]</a></p>
        <p>Pre správanie „šan-čaj“ je typické falšovanie nielen výrobkov, ale i celých obchodov. V krajine sa opakovane vyskytli zdokumentované prípady falošných obchodov Apple. Obchody sú starostlivo zariadené všetkou výbavou z reálnych obchodov Apple: majú sklenenú fasádu, stoly z ľahkého dreva, točité schodisko, plagáty produktov Apple, úhľadne usporiadané steny s výrobkami a s&nbsp;bielymi značkami Apple. Zamestnanci nosia charakteristické, tmavomodré tričká s logom Apple a dokonca sa zdá, že veria, že pracujú v&nbsp;reálnom obchode Apple.<a href="#_edn5" id="_ednref5">[5]</a></p>
        <p>Niektorí Číňania sa v spoločenskej atmosfére, charakterizovanej takýmito podvodmi, kvôli vlastnému prospechu nezastavia pred ničím a neobávajú sa žiadneho trestu, ani z nebies, ani od ľudí. Klamstvo a&nbsp;falšovanie sa stávajú súčasťou kultúry hlavného prúdu. Tých, ktorí takéto praktiky odmietajú, spoločnosť považuje za čudných.</p>
        <p>Stranícka kultúra poškodila tiež jazyk, ako môžeme vidieť v neustálom používaní hyperbolických slov a fráz. Reštaurácie nesú mená ako Najvyššie nebesá, Cisár nad všetkými cisármi alebo Kráľ kráľov. Literárne štýly a propaganda sa tiež vyžívajú v pompéznosti. Oficiálna propaganda pravidelne používa frázy ako „prvý na svete“, „najohromnejšie v histórii“, „i Spojené štáty sa boja“, „Japonsko je zdesené“, „Európa ľutuje“ a podobne.</p>
        <p>Správy sú plné titulkov ako: „Vedecká a technologická sila Číny prevalcovala Spojené štáty a radí sa na prvé miesto na svete“, „Čína opäť vyhrala prvé miesto na svete, osobne porazila americké modré čipy a úplne rozdrvila Apple“, „Stane sa niečo veľké. Kúzelná čínska zbraň znovu spôsobuje, že sa USA obávajú, svet je ohromený, Japonsko je úplne vystrašené“, „Čína je svetovou jednotkou na ďalšom poli! Dokončila historickú veľkú zmenu iba za tridsať rokov, čo ohromilo USA, Japonsko i Južnú Kóreu“ alebo „Spoločnosť Huawei oznámila, že vytvorila prvý 5G čip na svete, čo šokovalo celý svet!“.</p>
        <p>Tón propagandistických filmov, ako je napríklad <em>Bravo, moja vlasť!</em> alebo špeciálna séria televíznych programov <em>Výborne, moja krajina!</em>, sú tiež veľmi nadsadené. Vytvárajú dojem, akoby sa celý svet podroboval Číne. Vyjadrujú tak postoj, ktorý zaváňa propagandou používanou počas Veľkého skoku vpred, keď strana vyhlasovala, že „Čína prekoná Britániu a doženie Spojené štáty“.</p>
        <p>Nová vlna zveličovania je konkrétnym prejavom „falošného, prehnaného, ​​prázdneho étosu“ (ako sa mu v Číne hovorí) straníckej kultúry v internetovom veku. Základnou otázkou stále zostáva otázka poctivosti. Reforma Číny v 80. a 90. rokoch a následné otváranie krajiny priniesli do krajiny tie najhoršie aspekty súčasnej západnej kultúry, ako sú sexuálna sloboda, užívanie drog, homosexualita, počítačové hry a podobne. Zábavné televízne programy sú plné vulgarizmov a celá spoločnosť sa stala palácom rozkoše pre bezuzdné napĺňanie materiálnych a telesných túžob.</p>
        <p>Komunizmus premenil Čínu - krajinu, ktorá kedysi bývala civilizovaná &nbsp;&nbsp;veľkolepá a&nbsp;nádherná - na necivilizovaný národ.</p>
        <h2>2. Komunistické rozvracanie západnej masovej kultúry</h2>
        <p>Západné krajiny slobodného sveta boli známe svojimi civilizovanými spoločnosťami, kde boli muži dobre vychovaní a ženy boli cnostné a&nbsp;elegantné. Ľudia tu navzájom vychádzali čestne a priateľsky. Komunizmus však v západných krajinách implementoval svoju agendu, aby túto civilizáciu rozvrátil a sabotoval. Hoci k priamemu poškodeniu západnej civilizácie nemôže použiť násilie a totalitnú moc, ako tomu bolo v Číne, vyvolal v ľuďoch negatívne a vzdorujúce myslenie a správanie s cieľom podkopať tradície, zničiť verejné mravy a&nbsp;pokaziť morálku jednotlivcov.</p>
        <p>Kým verejnosť jasala nad víťazstvom spojencov v druhej svetovej vojne, komunisti už začali usilovne pracovať v&nbsp;oblasti ideológie a kultúry. V rámci úvah o vojne a nových vlnách ideológie prispela skupina komunistov k&nbsp;systematickému odklonu od tradícií, ktoré spájali ľudí s nebesami.</p>
        <p>V Spojených štátoch sa po druhej svetovej vojne objavila generácia beatnikov. Bola to skupina povojnových amerických spisovateľov, ktorí boli v 50. rokoch iniciátormi umeleckého a&nbsp;literárneho hnutia, ktorého cieľom bolo nanovo vymedziť kultúru. Kým oprávnene opovrhovali určitým pokrytectvom morálnej pokrivenosti v spoločnosti tej doby, ich odpoveď spočívala v cynickom odmietnutí a zvrhnutí všetkej tradičnej morálky. Obhajovali neobmedzenú slobodu, venovali sa pseudomystike, prepadli drogám a&nbsp;zločinu a usilovali o nedisciplinovaný a divoký život. Ich pokusy o&nbsp;radikálnu kritiku buržoáznej, kapitalistickej spoločnosti sa zhodovali s ideologickým náporom komunizmu na Západe, čím sa stali prirodzeným nástrojom ľavicového hnutia.</p>
        <p>Mnoho beatnikov bolo skutočne hlboko ovplyvnených komunistickou a socialistickou ideológiou. Napríklad zakladateľ hnutia Jack Kerouac napísal predtým než sa stal slávnym krátky príbeh „Zrodenie socialistu“, o svojej vzbure proti kapitalistickej spoločnosti.<a href="#_edn6" id="_ednref6">[6]</a> Ďalší zástupca hnutia, Allen Ginsberg, povedal, že neľutuje komunistické presvedčenie, ktoré kedysi zastával. Tiež podporoval pedofíliu (pozri jedenástu kapitolu). Diela beatnikov odmietali tradičné konvencie, chýbal im poriadok a používali vulgárny jazyk. Táto skupina predstavovala prvý veľký odklon od pravidiel a&nbsp;tradičných princípov a stála na začiatku kontrakultúrneho hnutia, ktoré v 60. rokoch zaplavilo západný svet.</p>
        <p>60. roky zažili rozvinutie a rozšírenie toho, čo zaviedli beatnici, so subkultúrami ako hippie, punk, goth a ďalšie. Tieto kontrakultúrne trendy si našli v západných mestách dychtivé publikum a jednu mladú generáciu za druhou vábili k násiliu, užívaniu drog, sexuálnemu oslobodeniu, nekonformnému oblečeniu, kultúrnemu odcudzeniu a&nbsp;sklonu ku temnote a smrti.</p>
        <p>V roku 1967, v rámci takzvaného Leta lásky (Summer of Love), sa v sanfranciskom Haight-Ashbury Parku a Golden Gate Parku na niekoľko dní zhromaždili tisícky členov hippies, ktorí bizarným správaním, užívaním drog, nahotou, skupinovým spevom, poéziou a rockovou hudbou chceli vyjadriť svoj odpor voči spoločnosti. Hnutie dosiahlo svoj vrchol v čase atentátov na Martina Luthera Kinga Jr. a Roberta Kennedyho a tiež počas eskalácie vietnamskej vojny. V lete 1969 sa na festivale vo Woodstocku, konajúcom sa na farme severozápadne od New Yorku, zhromaždilo viac ako 400 tisíc ľudí, ktorí sa pri skandovaní sloganov ako „láska“, „sloboda“ a „mier“ oddávali zhýralostiam. Woodstock bol pre 60. roky významnou kultúrnou udalosťou a počas nasledujúcich desaťročí sa newyorský Central Park, Golden Gate Park v San Franciscu a&nbsp;Woodstock stali symbolom americkej kontrakultúry.</p>
        <p>Zatiaľ čo sa v Spojených štátoch rozbiehala kontrakultúra, vo Francúzsku vypukli nepokoje, ktorých sa zúčastnili milióny ľudí. Udalosti sa dnes označujú ako „Parížsky máj“ alebo „Máj ’68“. Začalo to rozhnevanými študentmi, ktorí sa búrili proti tradičnej morálke a kultúre. Školy v tom čase mali internáty prísne oddelené podľa pohlavia a študenti mali zakázané navštevovať izby druhého pohlavia. Zrušenie tohto ustanovenia a právo na sex na študentských internátoch, boli jednými z hlavných cieľov prvých protestov. Vzbura študentov potom našla podporu u socialistov i&nbsp;komunistov vo Francúzsku.</p>
        <p>Hovorí sa, že koncom 60. rokov existovali dve centrá revolúcie: jedným bol Peking, kde bola v plnom prúde kultúrna revolúcia, a&nbsp;druhým bol Paríž, kde udalosti Mája ’68 otriasli svetom. Mnohí tieto udalosti nazvali kultúrnou revolúciou Západu. V tej dobe čínski študenti pochodovali so sloganmi a transparentmi na podporu rebelujúcich francúzskych študentov, zatiaľ čo v Paríži „západné Červené gardy“ nosili zelené vojenské čiapky a uniformy s červenými rukávnikmi na podporu maoistov v Číne. Na svojich sprievodoch títo mladí ľudia držali obrovské portréty Mao Ce-tunga. Ich hlavnou ideologickou oporou sa stali „Tri M“ – Marx, Mao a Marcuse.<a href="#_edn7" id="_ednref7">[7]</a></p>
        <p>Svoje vlastné kontrakultúrne hnutie zažilo v 60. rokoch tiež Japonsko. Miestna skupina Všejaponská liga študentskej samosprávy, spojená s japonskou komunistickou stranou, mala v tom čase medzi študentmi značný vplyv. Liga bola organizovaná japonskou komunistickou stranou v reakcii na aktivity Červených gárd v Číne. Spoločne s ďalšími ľavicovými študentskými organizáciami, ako boli Japonská červená armáda alebo Celokampusové výbory pre spoločný boj, usporiadala táto liga v Japonsku početné kontrakultúrne demonštrácie a zašla tak ďaleko, že obhajovala a páchala teroristické činy proti japonskej spoločnosti.<a href="#_edn8" id="_ednref8">[8]</a></p>
        <p>Podobný chaos zažívali ľudia tiež v niektorých latinskoamerických krajinách. Napríklad pod vplyvom kubánskej komunistickej strany sa mexické študentské hnutie mobilizovalo k protestom, ako bolo zhromaždenie na Plaza de las Tres Culturas. Ďalšie ľavicové študentské skupiny odoslali študentom v Paríži telegramy na podporu revolty počas Mája ’68.</p>
        <p>Z globálneho pohľadu vytvorili kontrakultúrne hnutia, ktoré sa súčasne vynorili vo viacerých krajinách, masívny útok komunizmu na tradičnú spoločnosť. Odveké morálne tradície a hodnoty, ktoré ľudstvu zanechali nebesá a ktoré sa rozvíjali po tisíce rokov, utrpeli pod dopadom tohto globálneho komunistického hnutia obrovské škody. Podobne ako v&nbsp;komunistickej Číne, kde bola dávna kultúra krajiny aktívne ničená totalitným režimom ČKS, hnutie kontrakultúry v&nbsp;slobodnom svete z&nbsp;veľkej časti uspelo v&nbsp;zničení pokladov západnej civilizácie a&nbsp;vo vykorenení viery v&nbsp;nebesá.</p>
        <h3>3. Populárna kultúra a spoločenský chaos</h3>
        <p>Spolu s útokmi na tradičnú kultúru z vnútra aj zvonku začali v spoločnosti zapúšťať korene negatívne prvky antitradičnej ideológie. Vzhľadom k svojmu globálnemu vplyvu je Amerika de facto lídrom v udávaní tónu populárnej kultúry na celom svete a korupcia americkej populárnej kultúry tak zasiahla celý svet. Ako už bolo povedané, niektoré tradične konzervatívne krajiny s&nbsp;hlbokými tradičnými kultúrami, ako sú Čína a Japonsko, nedokázali odchýleným trendom pochádzajúcim zo Spojených štátov odolať a&nbsp;začali ich napodobňovať. To isté platí prakticky pre každú krajinu a oblasť, ktoré boli vystavené globalizácii; neobmedzená, amorálna, antisociálna a hedonistická populárna kultúra sa rozšírila do celého sveta.</p>
        <h3>a) Hip hop a&nbsp;rokenrol</h3>
        <p>Tradičná hudba sa zameriavala na zušľachťovanie ľudí a&nbsp;kultivovanie cnosti. Pomáhala ľuďom, aby boli zdraví ako duševne, tak fyzicky. Výsledkom bol súlad v spoločnosti a harmónia medzi človekom a prírodou. Propagovala sa krásna hudba, ktorá oslavovala nebesia. Naproti tomu neharmonickou, chaotickou či neviazanou hudbou ľudia opovrhovali. Dnes je však populárna kultúra plná šokujúcich, skazených hudobných diel. Hip hop a rokenrol sú toho živým príkladom.</p>
        <p>Hip hop sa objavil v 70. rokoch v New Yorku. Počas niekoľkých posledných desaťročí sa hip hop, rap a breakdance dostali z&nbsp;New Yorku do ďalších krajín a stal sa z nich celosvetový hit. Hip hop prenikol do populárnej kultúry v Ázii, Európe a&nbsp;do mnohých afrických krajín. Napriek svojej zjavnej morálnej skazenosti a témam, ktoré sa týkajú promiskuity, zabíjania, násilia a drog, si táto hudba získala celosvetové uznanie a stretnete sa s ňou dokonca aj na pódiách preslávených divadiel.</p>
        <p>Počiatky rokenrolu sa datujú do 40. rokov 20. storočia. V 60. rokoch sa z neho stala hudba hnutia kontrakultúry. Hysterické vokály, divoké bubnovanie a prekrútené riffy elektrickej gitary rokenrolu môžu poslucháča priviesť do šialeného, ​​iracionálneho rozpoloženia. S odhodením racionality sa uvoľní démonická povaha, ktorá je za normálnych okolností vďaka civilizačným požiadavkám držaná na uzde. Prevládajúcou témou mnohých rockových subžánrov sa stali nihilizmus a ďalšie temné spôsoby myslenia. Psychedelický rock vyzýval k užívaniu drog, zatiaľ čo niektoré ďalšie, temnejšie formy rocku vyzývali k vzbure, samovražde a násiliu alebo povzbudzovali k promiskuite, cudzoložstvu, homosexualite a odmietaniu manželstva. Texty týchto piesní propagujú oplzlosti alebo obscénnosti, prípadne vychvaľujú zlo a zavrhujú Boha. Niektoré rockové superhviezdy ospravedlňovali sexuálne obťažovanie mladistvých dievčat, čo znecitlivelo publikum voči kultúre sexuálneho zneužívania a promiskuite.</p>
        <p>Niektoré tieto texty sú plné konfliktu: „Hej! Hovorím, že sa volám Výtržnosť/ Budem kričať a jačať / Zabijem Kráľa a&nbsp;budem nadávať všetkým jeho služobníkom“. Jedna pieseň bola nazvaná „Súcit s diablom“ (Sympathy for the Devil). Album istej psychedelickej rockovej skupiny sa volá „Žiadosť jeho satanskej výsosti“ (Their Satanic Majestic Request). V ďalšej slávnej rockovej piesni sa spieva: „Hej, Satan, platím svoje dlhy ... / Som na diaľnici do pekla“.</p>
        <p>Niektoré rockové piesne chvália socializmus a komunizmus. Pieseň „Imagine“ vyzývala poslucháčov, aby si predstavili komunistickú spoločnosť bez náboženstva, krajín a súkromného vlastníctva.</p>
        <p>Dokonca aj náboženské skupiny ťažko odolávajú negatívnemu vplyvu rokenrolu. Kresťanská cirkevná hudba mala chváliť Boha, avšak s popularitou rokenrolu moderná hudba kresťanských cirkví prijala rockové prvky, aby apelovala na mládež, čo dalo vznik tzv. súčasnej kresťanskej hudbe.<a href="#_edn9" id="_ednref9">[9]</a></p>
        <p>Sprievodnými javmi rokenrolu sú cudzoložstvo, násilie, dekadencia, užívanie drog, skazenosť a odpor k viere v Boha. Skorumpované správanie, ktoré tradičná morálka a viera zakazujú, išlo ruka v&nbsp;ruke s tým, ako popularita tohto žánru narastala.</p>
        <h3>b) Konzumácia drog</h3>
        <p>Konzumácia drog sa v posledných niekoľkých desaťročiach stala globálnym problémom. Ohniskom rozsiahlej konzumácie drog na Západe bola v raných fázach práve kontrakultúra. Vo svojej kampani proti buržoáznej morálke sa hnutie hippies snažilo dekonštruovať a&nbsp;podkopať všetky tradície a vybudovať vlastnú vieru, morálne normy a&nbsp;životný štýl. LSD a halucinácie z psilocybínových húb sa považovali za duchovné bádanie. Amfetamín alebo kokaín sa používali na povzbudenie a heroín a barbituráty ako utlmujúce drogy. To všetko malo údajne vyslobodiť človeka z tohto sveta a priviesť ho do iného stavu vedomia.</p>
        <p>Mnoho mladých ľudí z hnutia kontrakultúry sa intenzívne zaujímalo o východnú filozofiu a duchovnú kultiváciu. Psychedeliká sa však stali pre tých, ktorí sa usilujú o vyššie pochopenie, skratkou bez nutnosti vydržať ťažkosti spojené s kultiváciou mysle alebo znášanie fyzickej bolesti pri meditácii. Namiesto toho si jednoducho dali acidovú tabletku, ktorá im poskytla pseudoduchovný zážitok, aj keď ich nespojila s ničím skutočným. Prostredníctvom týchto drog jednoducho odovzdali svoje telo démonickým prvkom, ktoré vôbec nesúvisia s pravými, ortodoxnými kultivačnými praktikami. Tieto skúsenosti zviedli mnoho ľudí so skutočnými duchovnými ašpiráciami na odchýlenú cestu. Mnoho popových spevákov a rockových a hiphopových hviezd zomiera v mladom veku, často kvôli predávkovaniu.</p>
        <p>V&nbsp;dnešných Spojených štátoch sa najdlhšou a najsmutnejšou vojnou stala vojna proti drogám. Krajina sa po celé desaťročia venuje zatýkaniu a sledovaniu tisícov obchodníkov s drogami. Napriek tomu, že vládne úrady opakovane pred nelegálnym užívaním drog varujú, ich konzumácia stále pokračuje. V rokoch 2000 až 2018 zomrelo na predávkovanie opiátmi najmenej 300 tisíc Američanov. Prezident Trump označil 26. októbra 2017 opiátovú krízu za naliehavý problém a načrtol spôsob, ako proti tomuto javu bojovať.<a href="#_edn10" id="_ednref10">[10]</a></p>
        <p>Podľa správy Národného ústavu pre výskum užívania drog u dospievajúcich (National Institute on Drug Abuse for Teens) z roku 2017 sa medzi študentmi rozbujnelo užívanie marihuany: 45&nbsp;% študentov z posledného ročníka stredných škôl uviedlo, že aspoň raz fajčili marihuanu, a 37,1&nbsp;% z nich ju fajčilo niekedy za posledný rok. 71&nbsp;% študentov nepovažovalo časté užívanie marihuany za veľmi škodlivé.<a href="#_edn11" id="_ednref11">[11]</a></p>
        <p>Konzumácia extázy a fajčenie marihuany sa stali medzi mladými ľuďmi bežné, zatiaľ čo sa stále objavujú novšie a&nbsp;silnejšie drogy, ako je fentanyl. Fentanyl je syntetický opioid, ktorý je päťdesiatkrát účinnejší ako heroín a stokrát silnejší ako morfín. Je natoľko smrtiaci, že sa mu hovorí chemická zbraň. Dva miligramy môžu byť smrteľné.<a href="#_edn12" id="_ednref12">[12]</a> Takéto deštruktívne drogy teraz zaplavujú americké ulice desivým tempom a zabíjajú oveľa viac ľudí ako iné opioidy, jednoducho preto, že je veľmi ľahké sa nimi predávkovať.</p>
        <p>Podľa Národného ústavu pre analýzu zneužívania drog (National Institute on Drug Abuse) mal fentanyl a jemu podobné drogy v&nbsp;roku 2018 na svedomí približne 31 tisíc z&nbsp;celkového počtu približne 67 tisíc úmrtí z predávkovania.<a href="#_edn13" id="_ednref13">[13]</a> Médiá opakovane informujú o tom, že fentanyl sa vo veľkom dováža z Číny. V roku 2018 úrady vykonávajúce rutinnú inšpekciu v prístave vo Filadelfii objavili a zadržali 50 kilogramov fentanylu v náklade oxidu železitého z Číny. Trhová hodnota objavených drog bola 1,7 milióna dolárov.<a href="#_edn14" id="_ednref14">[14]</a></p>
        <p>V Číne sa konzumácia drog rozšírila ako rakovina v celej spoločnosti. Produkcia a užívanie drog, najmä tých syntetických, sú nekontrolovateľné. Internetový predaj drog sa tiež vymyká kontrole. Podľa správy Čínskej štátnej komisie pre kontrolu omamných látok (CNNCC) z roku 2015 presiahol počet užívateľov drog štrnásť miliónov. Skutočný počet je však pravdepodobne vyšší, pretože medzi užívateľmi drog sú stále častejšie pracovníci v administratíve, živnostníci, pracovníci v zábavnom priemysle a štátni úradníci.<a href="#_edn15" id="_ednref15">[15]</a></p>
        <p>Správa CNNCC o situácii v oblasti omamných látok z&nbsp;roku 2017 ukazuje, že čínske protidrogové oddelenia riešili 140 tisíc drogových prípadov, zlikvidovali 5 534 skupín obchodujúcich s drogami, zadržali 169 tisíc podozrivých z obchodovania s drogami, zhabali 89,2 tony drog a vykonali 870 tisíc razií, pri ktorých objavili 340 tisíc nových narkomanov.<a href="#_edn16" id="_ednref16">[16]</a></p>
        <p>Drogy sú často vysoko návykové, ničia ľuďom rodiny, kariéru, poškodzujú ich povesť a vzťahy, z ľudí sa stávajú kriminálnici a pri predávkovaní si môžu privodiť aj smrť. Užívanie a&nbsp;obchodovanie s narkotikami škodí jednotlivcom, rodinám i celej krajine. Stalo sa tak ďalším z ponurých javov, ktoré zaplavili dnešnú spoločnosť.</p>
        <h3>c) Pornografia a&nbsp;prostitúcia</h3>
        <p>Zo všetkých foriem revolúcie, na ktoré komunisti vyzývajú, je pravdepodobne najúplnejšia sexuálna revolúcia. Ak bolo zmocnenie sa politickej moci prejavom revolúcie proti vonkajším zložkám spoločnosti, sexuálne oslobodenie je prejavom komunistickej revolúcie zameranej dovnútra.</p>
        <p>Freudova pansexualita, teória, ktorá považuje všetky túžby a záujmy za niečo, čo vychádza zo sexuálneho inštinktu, poskytla teoretický základ pre sexuálne oslobodenie, zatiaľ čo objavenie sa orálnej antikoncepcie začalo oddeľovať sex od reprodukcie. Sexuálna revolúcia zasiahla tradičnú morálku a priniesla a propagovala radikálny feminizmus, potraty, predmanželský sex a hnutia za práva gayov a lesbičiek. Sexuálne oslobodenie vytvorilo skreslenú predstavu, že neviazaný pohlavný styk a obchod so sexom sú základnými ľudskými právami. To zničilo tradičnú sexuálnu etiku a obmedzenia a urobilo zo sexu len ďalšiu &nbsp;formu zábavy. Z ľudí sa tak stali len akési sexuálne nástroje a&nbsp;sexuálna sloboda pripravila pôdu pre pornografiu, ktorá infiltrovala a&nbsp;podkopala spoločnosť.</p>
        <p>Čo sa týka neviazaného sexu a podnikania s pornografiou, obzvlášť významnú úlohu zohral v 50. rokoch minulého storočia časopis Playboy. Kým sa počas protivojnového obdobia všade ozýval slogan „milujte sa, nebojujte“, sexuálne explicitný film Blue Movie (Pornofilm) sa stal v Spojených štátoch prvým svojho&nbsp; druhu, ktorý bol široko uvedený v kinách. Za zvukov rockovej hudby a s odmietaním tradícií sa tak na Západe vynorila pätnásťročná éra (1969 – 1984) „porno štýlu“.</p>
        <p>V 70. rokoch boli pornografické filmy dostupné len v&nbsp;pochybných kinách pre dospelých. Začiatkom 80. rokov priniesli videokazety pornografiu do miliónov domácností, avšak pravý boom nastal až s rozšírením internetu koncom 90. rokov a neskôr. V čase smartfónov začala byť pornografia dostupná kedykoľvek na požiadanie. Objem celosvetového obchodu s pornografiou v roku 2015 dosiahol 97 miliárd dolárov ročne, z toho v samotných Spojených štátoch to bolo 10 až 12 miliárd dolárov.<a href="#_edn17" id="_ednref17">[17]</a></p>
        <p>Zavedenie internetu a smartfónov prinieslo do pornopriemyslu zásadné zmeny. K&nbsp;celkovému objemu pornografickému obsahu, ktorému bol v 80. rokoch vystavený priemerný dospelý človek v&nbsp;období niekoľkých rokov, sa teraz môže dostať v priebehu niekoľkých minút aj dieťa. V priemere sú teraz deti vystavené pornografii už vo svojich ôsmich rokoch. Jeden 12-ročný britský chlapec sa stal natoľko závislým na online porne, že znásilnil svoju sestru. Žalobca, ktorý sa na prípade podieľal, k tomu uviedol: „Prípady tohto druhu sa budú pred súdom objavovať stále viac kvôli tomu, že mladí ľudia majú prístup k tvrdej pornografii.“<a href="#_edn18" id="_ednref18">[18]</a></p>
        <p>Ak je dieťa vystavené pornu, nesie to so sebou mnohé dôsledky, medzi ktoré patrí napríklad skorá sexuálna aktivita a zvýšený výskyt sexuálnych deliktov. Pornografia posilňuje dojem, že sex je skôr zábavou alebo transakčnou službou, než súčasťou súkromného manželského života, a že sexuálne správanie, ktoré možno vidieť v porne, je niečo bežného. To tiež prispieva k normalizácii sexuálnej skazenosti a zvrátenosti.</p>
        <p>Pornopriemysel v Japonsku sa už štandardizoval ako súčasť spoločnosti. V supermarketoch nájdeme regály plné časopisov a komiksov pre dospelých a v neskorých nočných reláciách v&nbsp;televízii sa bežne objavujú pornoherci. Pornoherečky sú predstavované ako idoly dospievajúcich a bežne sa objavujú v médiách. Japonský pornopriemysel mal na celú Áziu hlboko negatívny vplyv.</p>
        <p>Dokonca aj v prevažne moslimských krajinách, ako je Egypt či Tunisko, kde je pornopriemysel zakázaný islamom, funguje potajomky a bez väčších problémov.</p>
        <p>V mnohých európskych krajinách je prostitúcia legálna a mnohí Európania ju považujú za normálnu formu práce. V roku 1969 sa Dánsko stalo prvou krajinou, ktorá legalizovala obrázkovú pornografiu. Nórsko, ktoré malo jedno z najprísnejších obmedzení prostitúcie v Európe, legalizovalo tvrdú pornografiu v roku 2006.<a href="#_edn19" id="_ednref19">[19]</a> Obchod so sexom v Dánsku môže niekedy dokonca dotovať vláda. Napríklad, za účelom ochrany „rovnakých práv“, môže zdravotne postihnutý človek navštíviť nevestinec, pričom účet zaplatí daňový poplatník.<a href="#_edn20" id="_ednref20">[20]</a> Tento spôsob myslenia bol prvýkrát presadzovaný v 19. storočí zakladateľom utopického socializmu, Charlesom Fourierom.</p>
        <p>Čínska spoločnosť, ktorá bola dlho povestná konzervatívnou rodinnou etikou a morálnou zdržanlivosťou, a kde aj diskusia o sexe bola tabu, bola tiež v posledných desaťročiach zmetená vlnou sexuálnej degenerácie. Zo všetkých kampaní ČKS dosiahla najväčší „úspech“ bezpochyby kampaň za sexuálne oslobodenie, ktorá predbehla aj úspech otvorenia čínskej ekonomiky svetu. Počas tridsiatich rokov došlo k&nbsp;úplnej transformácii od „revolučnej disciplíny“ k „sexuálnemu oslobodeniu“. Prostitúcia je v Číne rozšírená. Z odhadov uskutočnených okolo roku 2010 vyplýva, že v krajine pôsobilo dvadsať až tridsať miliónov prostitútok.<a href="#_edn21" id="_ednref21">[21]</a> Čím viac má bohatý podnikateľ či skorumpovaný úradník mileniek, tým vyššie je jeho spoločenské postavenie. Čína sa často považuje za svetovú továreň, ale vyváža aj veľké množstvo&nbsp; ľahkých žien, a to do štátov a oblastí, ako Japonsko, Malajzia, krajiny Stredného východu, Spojené štáty, Európa a Afrika. Odhady z&nbsp;roku 2018 naznačujú, že v subsaharských krajinách pôsobí 13 až 18,5 tisíc čínskych prostitútok.<a href="#_edn22" id="_ednref22">[22]</a></p>
        <p>Krajiny juhovýchodnej Ázie a Južnej Ameriky sú na tom podobne. Mnoho miest sa stalo vyhlásenými destináciami pre sexuálnu turistiku, a hoci v týchto oblastiach môže byť prostitúcia oficiálne zakázaná, ide o tak silné odvetvie, že prispieva k&nbsp;hospodárskemu rastu daných štátov.</p>
        <p>Najpriamejším dôsledkom záplavy pornografie a prostitúcie v spoločnosti je deštrukcia rodín a manželstva. Obzvlášť pornu sa začalo hovoriť „tichý zabijak rodín“.<a href="#_edn23" id="_ednref23">[23]</a> Sledovanie pornografie spôsobuje nezáujem o zdravé rodinné vzťahy a živí sexuálnu túžbu a žiadostivosť, ktoré vytvárajú sexuálne nutkanie, často &nbsp;uspokojiteľné iba v mimomanželských vzťahoch či za použitia iných spôsobov, ako sú násilie či trestné činy.</p>
        <p>Počas vypočutia v americkom Senáte v roku 2005 predložil Jill Manning výskum rozvodových a manželských právnikov, ktorý ukázal, že u&nbsp;56&nbsp;% všetkých prípadov rozvodov v USA mal jeden z&nbsp;partnerov „obsedantný záujem o pornografické webové stránky“.<a href="#_edn24" id="_ednref24">[24]</a> V&nbsp;roku 2016, počas každoročného stretnutia Americkej sociologickej asociácie, sa prezentovala vedecká práca, ktorá ukazovala, že sa zdvojnásobil počet rozvodov manželstiev, v ktorých jeden z partnerov začal sledovať pornografiu, oproti počtu manželstiev, kde nikto z&nbsp;partnerov pornografiu nesledoval. Výskum ukázal, že ak začne sledovať porno manžel, miera rozvodovosti sa zvyšuje z&nbsp;5&nbsp;% na 10&nbsp;%, zatiaľ čo v prípade, že začne porno sledovať manželka, miera rozvodovosti sa zvyšuje zo 6&nbsp;% na 18&nbsp;%. Čím mladší je jednotlivec, tým pravdepodobnejší je rozvod.<a href="#_edn25" id="_ednref25">[25]</a></p>
        <p>Pred rokom 1950 sa prakticky všetky tradičné kultúry sveta pozerali na sex pred svadbou ako na niečo neslušného a niečo, čo je v rozpore s prikázaniami, ktoré zanechal Boh ľudstvu. Spoločenský tlak aj verejná mienka pomáhali takéto aktivity potláčať. Ak mladý pár čakal dieťa pred svadbou, očakávalo sa, že prevezmú zodpovednosť a vezmú sa, aby priviedli dieťa do úplnej rodiny. V tom čase väčšina ľudí verila, že ak muž privedie ženu do iného stavu, mal by sa, ak je slušne vychovaný, s&nbsp;partnerkou oženiť.<a href="#_edn26" id="_ednref26">[26]</a> Ak sa človek dopustil chyby, očakávalo sa, že za ňu prijme zodpovednosť.</p>
        <p>Avšak s morálnym úpadkom a vzostupom sexuálneho oslobodenia v 60. rokoch sa drasticky zvýšil počet tehotenstiev mimo manželstva. Všetko sa to udialo v&nbsp;rovnakom čase, ako začal mať porno priemysel väčší vplyv na verejnú mienku. V roku 1964 tvoril počet tehotenstiev mimo manželstva vo väčšine krajín, ktoré sú súčasťou Organizácie pre hospodársku spoluprácu a rozvoj, zvyčajne menej ako 10&nbsp;%. V roku 2014 to bola viac ako tretina. V Spojených štátoch bolo pôrodov mimo manželstva v roku 2014 v priemere 40&nbsp;% a medzi Afroameričanmi to bolo až 71&nbsp;%. Zo 140 miliónov detí, narodených na celom svete v roku 2016, približne 15&nbsp;%, to jest 21 miliónov, sa narodilo mimo manželstva.<a href="#_edn27" id="_ednref27">[27]</a></p>
        <p>Rodiny s jedným rodičom, tehotenstvo mimo manželstva a rozvody sa často úzko spájajú s chudobou. Takéto rodiny potom neúmerne zaťažujú systém sociálneho zabezpečenia.</p>
        <h3>d) Videohry</h3>
        <p>Mnoho detí dnes trávi nespočetné množstvo hodín hraním počítačových hier a videohier. Vývojári hier ich robia stále realistickejšie, dynamickejšie a interaktívnejšie. Obsahujú tiež čím ďalej tým viac násilia a erotiky. Deti i dospelí sa ľahko stávajú na hrách závislými, čo sa stalo veľkým problémom nielen pre rodičov, ale aj školy a dokonca vlády.</p>
        <p>Z videohier sa stala forma populárnej kultúry, ktorá ľudí sprevádza od detstva až do dospelosti. Avšak aká je to kultúra? Ide o deštrukčnú kultúru, ktorá sa v ničom neodlišuje od drog. Tí, ktorí sú na počítačových hrách závislí, nedokážu vidieť ich zápory s triezvym a objektívnym náhľadom. Jednoducho si myslia, že hry sú dobrá zábava a že sú zaujímavé, a nevzdajú sa ich, kým existuje úroveň, do ktorej sa dá postúpiť alebo vyšší počet bodov, čo sa dá dosiahnuť.</p>
        <p>Okrem toho takmer všetky dnešné videohry, od svojej zápletky až po estetickú atmosféru, propagujú násilie a zabíjanie, alebo majú erotický či chladnokrvný obsah. Jednoducho povedané, všetky tieto veci apelujú na démonickú povahu človeka. Pre dospievajúcich ľudí je niečo také nevhodné a škodlivé. Tým, že mladí hráči zažívajú pocit vzrušenia zo zabíjania, ničenia, násilia a bojov, môžu získať sklon k nevhodným myšlienkam a správaniu a celkovo znecitlivieť. Niektorých jedincov to dokonca môže prinútiť k spáchaniu zločinov.</p>
        <p>Online hry sú ešte návykovejšie. V minulosti hry slúžili k skráteniu času, keď sa ľudia nudili a cítili samotu. V&nbsp;dnešnej dobe sa z online hier stal šport a samostatná spoločenská činnosť, a platí to najmä pre deti. Vzhľadom k tomu, že sa do hry zapája veľké množstvo hráčov, môžu byť týmto virtuálnym svetom pohltené rýchlejšie.</p>
        <p>Do týchto hier sa investuje obrovské množstvo úsilia a kapitálu, a deti, ktoré ich nehrajú, sa môžu cítiť vyradené z kolektívu. A&nbsp;tak rodičia takmer proti svojej vôli dovoľujú svojim deťom, aby sa stali súčasťou hernej komunity na internete, len aby sa neskôr stali svedkami toho, ako si ich deti vyvíjajú závislosť. Počítačové hry kradnú deťom a mladým ľuďom čas, ktorý by mali vynaložiť na štúdium, vonkajšie aktivity a normálnu socializáciu. Namiesto toho sa stávajú zajatcami videohier.</p>
        <p>Akademik Erik Hurst vyhlásil, že dovolil svojmu 12 ročnému synovi hrať videohry len pár hodín cez víkendy, a to až po dokončení domácich úloh. Ak by však synovi ponechal voľnú ruku, hral by takmer nepretržite, a dokonca by ani nejedol a&nbsp;nesprchoval sa, len aby mohol hrať.<a href="#_edn28" id="_ednref28">[28]</a></p>
        <p>Výskum tohto vedca ukázal, že počítačové hry berú mladým ľuďom všetok voľný čas. Dáta ukazujú, že mladí dospelí, najmä tí s nižším príjmom a s nižšou mierou vzdelania, stále častejšie nachádzajú šťastie vo videohrách, čo u nich redukuje čas strávený prácou a v reálnom svete.<a href="#_edn29" id="_ednref29">[29]</a> Ide o bežný jav nielen v&nbsp;Spojených štátoch, ale aj v ďalších rozvinutých krajinách. Hurst vypozoroval v dnešnej spoločnosti trend, keď videohry vedú mladých dospelých ľudí k tomu, aby odmietli vstúpiť na trh práce, a namiesto toho sa spoliehali na rodičov, že ich budú finančne podporovať. Nie je príliš pravdepodobné, že by im videohry pomohli si niečo zarobiť, alebo že si budú schopní zlepšiť svoje zručnosti, či si nájsť lepšie zamestnanie. Keď sa z týchto mladých ľudí stanú rodičia, nebudú schopní dobre vychovávať svoje deti. Počítačové hry tak už začali podkopávať normálny ľudský život.</p>
        <p>Videohry sú ako duševné drogy. Oproti produkcii tvrdých drog ako heroín, ktoré sú vo väčšine krajín zakázané, je vývoj videohier dôležitým priemyselným odvetvím. Aké to má dôsledky? Firmy vyrábajú drogy, ktoré ničia budúcu generáciu, a štáty, ktoré tento sektor podporujú, tak sabotujú vlastnú budúcnosť.</p>
        <p>Vznik internetu a mobilných telefónov otvoril hernému priemyslu ešte väčší trh. Výskumná firma Newzoo vo svojej správe z apríla 2018 o globálnom trhu videohier predpovedala, že hráči po celom svete vynaložia v roku 2018 na hry 137,9 miliardy dolárov, čo predstavuje nárast o 13,3&nbsp;% oproti predchádzajúcemu roku. Viac ako polovica príjmov z videohier pochádza z mobilného segmentu. Výnosy z&nbsp;digitálnych hier tvoria 91&nbsp;% globálneho trhu.</p>
        <p>Správa tiež predpovedala, že trh s hrami si v najbližších niekoľkých rokoch udrží dvojciferný rast. Kým tempo rastu HDP v&nbsp;mnohých krajinách bojuje s jednocifernými číslami, herný priemysel vo veľkom expanduje. Očakáva sa, že samotný trh s mobilnými videohrami dosiahne do roku 2021 hodnotu sto miliárd dolárov. Poprednými tromi krajinami na globálnom hernom trhu budú podľa správy Čína, Spojené štáty a Japonsko, pričom Čína by mala predstavovať viac ako 25&nbsp;% celkového trhu.<a href="#_edn30" id="_ednref30">[30]</a></p>
        <p>Tradičné hry, vrátane športu a ďalších vonkajších aktivít, sú obmedzené prírodným prostredím, počasím, vybavením a&nbsp;fyzickou silou. Hráči si na nich zvyčajne nevyvíjajú závislosť. Videohry neobsahujú žiadne takéto obmedzenia. Hráči sú pozývaní a lákaní, aby sa ponorili do virtuálneho sveta a hrali nonstop, bez spánku a&nbsp;bez akýchkoľvek prestávok. Okrem toho tieto hry zriedkakedy prinášajú niečo poučného a kladného, ​​čo znamená, že tí, ktorí ich hrajú, sú čím ďalej viac vystavovaní vplyvu negatívnych prvkov.</p>
        <h3>e) Kultúra násilia&nbsp;</h3>
        <p>V Amerike vzrástla od roku 1960 do roku 2016 celková populácia 1,8-krát, zatiaľ čo celkový počet zločinov vzrástol 2,7-krát a&nbsp;počet násilných trestných činov vzrástol 4,5-krát.<a href="#_edn31" id="_ednref31">[31]</a></p>
        <p>Päťdesiat rokov predtým, než došlo v roku 1966 k vražednému incidentu v hlavnej výškovej budove Univerzity of Texas, bolo podľa spisovateľa a kriminalistu Granta Duweho dovtedy zaznamenaných len 25 masových strelieb na verejnosti so štyrmi alebo viacerými obeťami. Od tej doby sú masové streľby čím ďalej vražednejšie.<a href="#_edn32" id="_ednref32">[32]</a> Od masovej streľby v meste Killen v Texase v roku 1991, pri ktorej zomrelo 23 ľudí, až po masovú streľbu v Las Vegas v roku 2017, kde bolo zmasakrovaných 58 ľudí, je každý ďalší incident šokujúcejší.</p>
        <p>Počet teroristických incidentov po celom svete stúpol z 651 ročne v roku 1970 na 13&nbsp;626 v roku 2016, čo je dvadsaťnásobný rast. Od teroristického útoku z 11. septembra 2001 stúpol do roku 2018 počet teroristických útokov každoročne päťnásobne.<a href="#_edn33" id="_ednref33">[33]</a></p>
        <p>Veľa násilných činov v reálnom svete je odrazom toho, ako sú ľudia ponorení do kultúry násilia prostredníctvom masmédií. Násilia je plná nielen prudká hudba heavy metalu, ale aj väčšina zábavy, vrátane filmu, televízie a videohier, znázorňuje alebo sa točí okolo násilia. Mnoho filmových a televíznych diel vyobrazuje mafiu, gangy a pirátov v pozitívnom svetle, aby tieto negatívne postavy vyzerali príťažlivo a úctyhodne, čo viedlo k tomu, že k nim ľudia nielen prestali cítiť odpor, ale sami začali vidieť zločin a zločincov v&nbsp;pozitívnom svetle.</p>
        <p>Videohry poskytli ľuďom ďalší kanál pre velebenie násilia, ktorý vzhľadom k svojmu interaktívnemu rozhraniu umožňuje hráčom zapojiť sa do násilností vo virtuálnom svete. Namiesto nepriamej indoktrinácie násilím prostredníctvom filmu a televízie prežívajú hráči prostredníctvom týchto hier násilie sami; &nbsp;nechýbajú scény ako rozbíjanie hláv, sekanie tiel a všade striekajúca krv. To všetko presahuje normálne hranice filmu a televízie.</p>
        <p>V štúdii uverejnenej v roku 2013 analyzovali vedci filmy, ktoré boli vyrobené medzi rokmi 1985 až 2012. Zistili, že sa vo filmoch v&nbsp;kategórii PG-13 (nevhodný obsah pre deti do 13 rokov, pozn. prekl.) v týchto rokoch strojnásobilo množstvo násilia za použitia strelných zbraní.<a href="#_edn34" id="_ednref34">[34]</a> Následná štúdia filmov z rokov 2013 až 2015 ukázala, že tento trend stále pokračuje.<a href="#_edn35" id="_ednref35">[35]</a> V roku 2008 zistilo Pew Research Center, že 97&nbsp;% mladých ľudí vo veku od 12 do 17 rokov hralo videohry a že dve tretiny z&nbsp;nich hrá kategórie hier, ktoré zvyčajne obsahujú násilie.<a href="#_edn36" id="_ednref36">[36]</a></p>
        <p>Tvárou v tvár problému narastajúceho násilia v&nbsp;spoločnosti, prichádzajú odborníci, učitelia i široká verejnosť s návrhmi teórií a riešení, od prísnejších obmedzení prostredníctvom zákonov, cez väčšie postihy, až po psychologické poradenstvo. Takéto riešenia sú však podobné tomu, ako keď sa jedovatému stromu odrežú vetvy, namiesto toho, aby bol vykopaný aj s koreňmi.</p>
        <h3>f) Úpadok módy</h3>
        <p>Na povrchu sa zdá, že rôzne formy podivného oblečenia, správania a iných bežných prvkov populárnej kultúry v dnešnej spoločnosti sú súčasťou „slobody prejavu“ alebo súčasného „módneho trendu“, ale v skutočnosti je za tým niečo viac. Keď vystopujeme tieto javy k ich zdroju, odhalíme ich spojenie s komunistickým nátlakom proti tradícii a viere. Hoci sa tieto trendy na začiatku môžu stretnúť s odporom spoločnosti, ľudia si na ne časom jednoducho zvyknú a prestanú ich považovať za niečo zvláštne. To má za následok, že sa tieto negatívne faktory postupne stávajú všeobecne prijímanou súčasťou každodenného života a kultúry.</p>
        <p>Napríklad dnes si spoločnosť už zvykla na ženy s krátkymi vlasmi. Tento štýl pochádza od emancipovaných žien (tzv. flappers) zo Západu z 20. rokov. Tieto ženy a dievčatá boli ovplyvnené prvou vlnou hnutia za práva žien a&nbsp;za sexuálne oslobodenie (pozri Siedmu kapitolu). Nosili krátke šaty, skracovali si vlasy, počúvali jazz, nosili silný make-up, pili alkohol, fajčili cigarety a mali neviazaný prístup k sexu. Nosenie krátkych vlasov bol spôsob, akým vyjadrovali pohŕdanie tradičnými úlohami pohlaví a svoju snahu o „oslobodenie“ žien.</p>
        <p>Potom, čo účes nadobudol na popularite, jedna známa operná speváčka napísala: „Mikádo je stav mysle, a nie iba nový spôsob úpravy mojich vlasov ... Skrátením svojich dlhých vlasov sa zbavujeme jedného z mnohých malých pút, ktoré ženy odhadzujú na svojej ceste k slobode.“<a href="#_edn37" id="_ednref37">[37]</a></p>
        <p>Počas Veľkej hospodárskej krízy v 30. rokoch tento krátky účes u žien postupne stratil na obľube, avšak v 60. rokoch, kedy sa vzopretie tradičným normám opäť stalo trendom, zažívali krátke účesy u žien znovu návrat. Podobne, dlhé účesy u mužov v tej istej dobe pochádzali od beatnikov a hippies.<a href="#_edn38" id="_ednref38">[38]</a> Hoci dlhé vlasy možno u mužov vysledovať až do dávnych dôb, na Západe si muži od prvej svetovej vojny vlasy skracovali. Hnutie kontrakultúry v 60. rokoch presadzovalo dlhé vlasy u&nbsp;mužov ako formu vzbury.</p>
        <p>Hlavný prúd spoločnosti bol najskôr v&nbsp;tvrdej opozícii voči mladým ľuďom, ktorí sa obliekali netradičným spôsobom. Postupom času si však ľudia na netradičné trendy zvykli a progresivizmus to pripisuje zvýšenej sociálnej tolerancii. V tradíciách Východu a Západu sa však rozdiely medzi mužmi a ženami neprejavovali len vo fyziológii a v ich rôznych úlohách v spoločnosti i v&nbsp;rodine, ale odrážali sa aj v oblečení, účesoch, reči a správaní.</p>
        <p>Komunizmus sa spolu s odstránením triednych rozdielov v&nbsp;spoločnosti snaží eliminovať sexuálne rozdiely medzi mužmi a ženami. Rovnako tak LGBT a feministické hnutia používajú slogan „rovnosť“ na zahmlenie rozdielov pohlaví v sociálnych a rodinných úlohách. Androgýnne módne trendy ďalej mažú a prevracajú rozdiely v oblečení. Tieto faktory slúžia na to, aby sa pripravila cesta k širšiemu spoločenskému prijatiu toho, čo sa tradične považovalo za deviantné sexuálne praktiky a životný štýl, a ďalej prispievajú&nbsp; k&nbsp;podkopávaniu tradičnej morálky.</p>
        <p>Morálne štandardy na Východe i na Západe po tisíce rokov objasňovali rozdiely medzi mužmi a ženami. Muž a žena majú, rovnako ako jin a jang, každý svoje príslušné úlohy. Komunizmus prevrátil jin a jang v nádeji, že zvráti tradičnú morálku a postaví proti sebe jednotlivcov v mene oslobodenia.</p>
        <p>Keď si uvedomíme túto agendu, uvidíme, že hoci sa rôzne odchýlky v obliekaní a móde môžu na povrchu javiť ako zdanlivý vývoj a posun vkusu, majú v skutočnosti za cieľ podkopať ľudskú spoločnosť. Väčšina súčasnej módy zdôrazňuje oplzlosť, ktorá má korene v hnutí kontrakultúry 60. rokov.<a href="#_edn39" id="_ednref39">[39]</a></p>
        <p>Ďalším znakom kultúrneho úpadku je fenomén „groupie“, ktorý bol populárny medzi mladými ľuďmi ako ďalší vedľajší produkt kontrakultúry. V 60. rokoch, kedy bola na Západe populárna rocková hudba, skupiny mladých dievčat, posadnutých rockovými hviezdami, jazdili na ich vystúpenia a poskytovali osobné a sexuálne služby pre členov kapely. Tieto mladé ženy sa stali obeťami módneho pobláznenia.<a href="#_edn40" id="_ednref40">[40]</a> Dnes mladí ľudia obdivujú hviezdy, ktoré propagujú zmätenú sexuálnu identitu. Mužské celebrity sa správajú žensky a naopak.</p>
        <p>Existuje tiež takzvaná módna punková subkultúra. Podobne ako v hnutí hippies, aj punkeri sa búria proti tradícii a podporujú nihilizmus. Väčšina stúpencov hippies boli rebelantskí mladí ľudia z&nbsp;tradičných rodín zo stredných vrstiev, zatiaľ čo k punku sa hlásili skôr ľudia z nižších sociálnych tried, ktorí sa búrili proti spoločenským konvenciám.<a href="#_edn41" id="_ednref41">[41]</a> Aby punkeri dôsledne vyjadrili svoje antitradičné postoje, často dávajú na obdiv svoje bizarné účesy alebo nosia roztrhané oblečenie plné hrotov a spôn. Farbia si vlasy, tetujú sa, prepichávajú si celé telo a niekedy odkrývajú časti tela, ktoré človek bežne skrýva. Punk sa stal inšpiráciou pre mnohé súčasné módne trendy.</p>
        <p>Punk obhajuje hedonizmus, preto jedným z&nbsp;populárnych punkových sloganov je heslo „ži rýchlo, zomri mladý a&nbsp;zanechaj po sebe peknú mŕtvolu“. To plne odráža tragédiu stratenej viery v Boha, kedy sa ľudia nechajú zviesť do priepasti hedonizmu a materializmu. Jednotlivci a spoločnosť by mali byť týmto sebadeštruktívnym nihilizmom znepokojení, väčšina z nich je však ponorená do súčasnej popkultúry príliš hlboko, aby to mohla postrehnúť.</p>
        <p>Súčasná odchýlená a pokrivená kultúra je v&nbsp;každodennom živote všadeprítomná: objavujú sa strašidelné alebo démonické obrázky na oblečení alebo v hudbe, škaredé obrázky na tetovaní a ohyzdné detské hračky či ozdoby. Literárne, filmové a&nbsp;televízne diela sú plné démonov, duchov a nadprirodzených hororových postáv. Deštruktívny a nihilistický obsah je aj na internete.</p>
        <h2>4. Obnova morálnych základov ľudskej kultúry</h2>
        <p>Každý má právo usilovať sa o šťastie – avšak spolu s právom prichádza zároveň zodpovednosť držať sa v medziach morálnych meradiel. Nadmerné usilovanie sa o potešenie nevyhnutne prinesie utrpenie, pohromu a smútok.</p>
        <p>Tradičná kultúra ľudstva nezakazuje rozumné uspokojovanie túžob. Avšak učí ľudí, aby svoje túžby ovládali a zvolili si zdravý životný štýl. Váži si harmóniu s prírodou, tradičné práce, harmonické rodinné vzťahy, zdravú občiansku spoločnosť a&nbsp;účasť na riadení samosprávy a štátu. Rovnako tak sem patrí tradičné umenie, literatúra, šport a zábava. Všetko toto prináša šťastie a&nbsp;uspokojenie, prospieva človeku na tele a mysli, a má prínos aj pre celú spoločnosť.</p>
        <p>Avšak konečným cieľom komunizmu je zničenie ľudstva. Jedným z&nbsp;krokov v tomto procese je rozbitie morálky a odstránenie Boha z&nbsp;ľudskej kultúry. Cieľom teda je, aby bola populárna kultúra a životný štýl presýtené negativitou a temnotou. V posledných niekoľkých desaťročiach sa práve takáto kultúra vytvorila na Východe i&nbsp;na Západe. Sebeckosť, hedonizmus a nihilizmus sa stali bežne prijímanými, a dokonca módnymi postojmi.</p>
        <p>Sex, drogy, rocková hudba a videohry stimulujú a zväčšujú túžby človeka. Mnohí sa týmto veciam oddávajú, aby unikli utrpeniu a&nbsp;sklamaniu zo života, ale nikdy sa nezastavia, aby sa nad sebou zamysleli. Tieto závislosti prinášajú iba okamžité uspokojenie, ktoré je nasledované ešte väčšou bolesťou a osobnou katastrofou. Konzumácia drog spôsobuje choroby, úmrtia a poruchy osobnosti. Chaotické sexuálne vzťahy ničia rodinu, čím ľudia strácajú dôveru a&nbsp;teplo domova, a videohry spôsobujú, že sa ľudia stratia vo falošnom svete. Závislí ľudia majú pocit, že si užívajú život naplno, ale v&nbsp;skutočnosti sú jednoducho zneužívaní vonkajšími silami. Jedinou vecou, ​​ktorá na nich čaká, je duchovný rozklad a fyzická smrť.</p>
        <p>To isté platí pre spoločnosti a národy. Keď sa veľké množstvo ľudí oddáva túžbam a pôžitkom, výsledkom bude nešťastie.</p>
        <p>Boh stvoril ľudstvo a dáva každému jednotlivcovi slobodnú vôľu. Ľudia by nemali zneužívať svoju slobodu a pokračovať na ceste degenerácie. Namiesto toho by mali túto slobodu dobre využiť a&nbsp;rozhodnúť sa pre návrat k tradičnej kultúre a tradičnému spôsobu života. Boh sa vždy staral o ľudstvo a ochraňoval ho. Ale to, či sa ľudia môžu vrátiť na správnu cestu, celkom závisí na voľbe každého jednotlivca.</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p><a href="#_ednref1" id="_edn1">[1]</a> George Washington, citácie podľa: „George Washington’s Rules of Civility and Decent Behavior in Company and Conversation“, <em>Foundations Magazine,</em> <a href="http://www.foundationsmag.com/civility.html.">http://www.foundationsmag.com/civility.html.</a></p>
        <p><a href="#_ednref2" id="_edn2">[2]</a> Benjamin Franklin, <em>The Autobiography and Other Writings on Politics, Economics, and Virtue, ed. Alan Houston</em> (Cambridge, UK: Cambridge University Press, 2004), 68–69.</p>
        <p><a href="#_ednref3" id="_edn3">[3]</a> Xue Fei 薛飞 „,Que nage shen jiu zao yige’: Hebei nainai miao luanxiang“ “缺哪个神就造一个” 河北奶奶庙乱象 [„,If a god is missing, just make one’: Chaos at the grandmother temple, Hebei“], <em>The Epoch Times</em>, 10. augusta 2017, <a href="http://www.epochtimes.com/gb/17/8/9/">http://www.epochtimes.com/gb/17/8/9/</a> n9513251.htm. [V čínštine]</p>
        <p><a href="#_ednref4" id="_edn4">[4]</a> „Oxford Dictionary Adds Popular Chinese Terms“, <em>China Daily</em>, 6. septembra 2010, <a href="http://www.chinadaily.com.cn/business/2010-09/06/content_11259791.htm.">http://www.chinadaily.com.cn/business/2010-09/06/content_11259791.htm.</a></p>
        <p><a href="#_ednref5" id="_edn5">[5]</a> Loretta Chao, „The Ultimate Knock-Off: A Fake Apple Store“, <em>The Wall Street Journal,</em> 21. júla 2011, <a href="https://blogs.wsj.com/chinarealtime">https://blogs.wsj.com/chinarealtime</a> / 2011/07/21 / the-ultimate-knock-off-a-fake-apple-store /.</p>
        <p><a href="#_ednref6" id="_edn6">[6]</a> Jack Kerouac, „The Birth of a Socialist“, <em>Atop an Underwood: Early Stories and Other Writings</em>, ed. Paul Marion (New York: Penguin, 2000).</p>
        <p><a href="#_ednref7" id="_edn7">[7]</a> Roberto Franzosi, recenzia knihy Jeremiho Suriho „Power and Protest: Global Revolution and the Rise of Détente“, <em>American Journal of</em> <em>Sociology </em>111, č. 5 (marec 2006), 1589, https: //www.journals.uchicago.edu / doi / full / 10.1086 / 504653.</p>
        <p><a href="#_ednref8" id="_edn8">[8]</a> Meredith Box a Gavan McCormack, „Terror in Japan“, <em>The Asia-Pacific Journal: Japan Focus 2</em>, č. 6 (25. jún 2004), 1570, <a href="https://apjjf.org/-Meredith-Box-Gavan-McCormack">https://apjjf.org/-Meredith-Box-Gavan-McCormack</a> / 1570 / article.pdf.</p>
        <p><a href="#_ednref9" id="_edn9">[9]</a> Amy D. McDowell, „Contemporary Christian Music“, <em>Oxford Music Online</em>, 2013, <a href="https://doi.org/10.1093/gmo/9781561592630.article.A2234810.">https://doi.org/10.1093/gmo/9781561592630.article.A2234810.</a></p>
        <p><a href="#_ednref10" id="_edn10">[10]</a> White House, <em>Ending America’s Opioid Crisis</em>, stránka navštívená 29. apríla 2020, <a href="https://www.whitehouse.gov/opioids/.">https://www.whitehouse.gov/opioids/.</a></p>
        <p><a href="#_ednref11" id="_edn11">[11]</a> US National Institute on Drug Abuse for Teens, <em>Drug Facts: Marijuana</em>, naposledy revidované v decembri 2019, stránka navštívená 29. apríla 2020, <a href="https://teens.drugabuse.gov/drug-facts/marijuana.">https://teens.drugabuse.gov/drug-facts/marijuana.</a></p>
        <p><a href="#_ednref12" id="_edn12">[12]</a> US Drug Enforcement Administration, 2019 <em>National Drug Threat Assessment</em>, december 2019, 9,</p>
        <p><a href="https://www.dea.gov/sites/default/files">https://www.dea.gov/sites/default/files</a> / 2020-02 / DIR-007-20% 202019% 20National% 20Drug% 20Threat% 20Assessment% 20-% 20low% 20res210.pdf.</p>
        <p><a href="#_ednref13" id="_edn13">[13]</a> National Institute on Drug Abuse, <em>Overdose Death Rates,</em> upravené v marci 2020, stránka navštívená 29. apríla 2020, https: // www.drugabuse.gov/related-topics/trends-statistics/overdose-death-rates.</p>
        <p><a href="#_ednref14" id="_edn14">[14]</a> Amanda Hoover, „110 Pounds of Fentanyl Seized at Port in Shipment from China,“ <em>New Jersey Advance Media</em>, 2. júla 2018, https: // www.nj.com/news/index.ssf/2018/07/110_pounds_of_fentanyl_found_in_</p>
        <p>philadelphia_port.html.</p>
        <p><a href="#_ednref15" id="_edn15">[15]</a> „Zhongguo dupin baogao: quanguo xidu renshu yu 1400 wan“ 中国毒品报告：全国吸毒人数逾1400万[ „China Drug Report: More than 14 million drug users Nationwide“], BBC Chinese, 24. júna 2015, <a href="http://www.bbc.com/zhongwen/simp/china">http://www.bbc.com/zhongwen/simp/china</a> / 2015/06 / 150624_china_drugs_report. [V čínštine]</p>
        <p><a href="#_ednref16" id="_edn16">[16]</a> Zhang Yang, 张洋 „Quanguo pohuo dupin xingshi anjian 14 wan qi“ 全国破获毒品刑事案件14万起 [„140&nbsp;000 drogových kriminálnych prípadov rozriešených v&nbsp;celej Číne“] in: „2017 nian Zhongguo dupin xingshi baogao 2017“ 年中国毒品形势报告 [„Správa o&nbsp;drogovej situácii v&nbsp;Číne za rok 2017“], <em>People’s Daily, </em>26. júna 2018, <a href="http://yuqing.people.com.cn/">http://yuqing.people.com.cn/</a> n1 / 2018/0626 / c209043-30088689.html. [v čínštine]</p>
        <p><a href="#_ednref17" id="_edn17">[17]</a> „Things Are Looking Up in America's Porn Industry“, <em>NBC News,</em> 20. januára 2015, <a href="https://www.nbcnews.com/business/business-news/things-are-looking-americas-porn-industry-n289431.">https://www.nbcnews.com/business/business-news/things-are-looking-americas-porn-industry-n289431.</a></p>
        <p><a href="#_ednref18" id="_edn18">[18]</a> „Boy, 12, Repeatedly Raped Sister after Becoming Fascinated with Internet Porn“, <em>New Zealand</em>, 7. novembra 2016, https: //www.nzherald.co.nz/world/news/article.cfm?c_id=2&amp;objectid=11743460.</p>
        <p><a href="#_ednref19" id="_edn19">[19]</a> Inga Margrete Ydersbond, „The ,Promiscuous’ and the ,Shy’: Denmark and Norway: A Historic Comparative Analysis of Pornography Legislation“, <em>The NPPR Working Paper Series: The Politics of Commercial Sex,</em> (marec 2012), <a href="https://www.duo.uio.no/bitstream/handle">https://www.duo.uio.no/bitstream/handle</a> / 10852/34447 / NPPRWP201201.pdf? sequence = 1.</p>
        <p><a href="#_ednref20" id="_edn20">[20]</a> Lars Gravesen, „Taxpayers Foot Bill for Disabled Danes’ Visits to Prostitutes“, <em>The Daily Telegraph,</em> 2. októbra 2005, <a href="https://www.telegraph.co.uk/news/worldnews/europe/denmark/1499735/Taxpayers-foot-bill-for-disabled-Danes-visits-to-prostitutes.html.">https://www.telegraph.co.uk/news/worldnews/europe/denmark/1499735/Taxpayers-foot-bill-for-disabled-Danes-visits-to-prostitutes.html.</a></p>
        <p><a href="#_ednref21" id="_edn21">[21]</a> Chen Jing, 沉靜, „Fanrong changsheng de chaoji xingdaguo“ 繁榮娼盛的超級性大國 [„Prosperous Prostitution in a Sex Industry Superpower“], <em>The Epoch Times</em>, 25. septembra 2009, <a href="https://www.epochtimes.com/b5/9/9/25/n2668274.htm">https://www.epochtimes.com/b5/9/9/25/n2668274.htm</a> [V čínštine]</p>
        <p><a href="#_ednref22" id="_edn22">[22]</a> Takudzwa Hillary Chiwanza, „Thousands of Chinese Prostitutes Are Flocking to Africa for Lucrative Fortunes“, <em>African Exponent</em>, 7. mája 2018, <a href="https://www.africanexponent.com/post/8965-chinese-prostitutes-have-joined-the-scramble-for-africas-fortunes.">https://www.africanexponent.com/post/8965-chinese-prostitutes-have-joined-the-scramble-for-africas-fortunes.</a></p>
        <p><a href="#_ednref23" id="_edn23">[23]</a> Pat Fagan, „The Effects of Pornography on Individuals, Marriage, Family and Community“, <em>Family Research Council</em> (marec 2011), stránka navštívená 29. apríla 2020, <a href="https://downloads.frc.org/EF/EF11C36.pdf.">https://downloads.frc.org/EF/EF11C36.pdf.</a></p>
        <p><a href="#_ednref24" id="_edn24">[24]</a> US Congress, Senate, Subcommittee on the Constitution, Civil Rights, and Property Rights, <em>Hearing on Pornography’s Impact on Marriage and the Family</em>, 109th Cong., 9. novembra 2005,</p>
        <p>https: //www.judiciary. senate.gov/imo/media/doc/manning_testimony_11_10_05.pdf.</p>
        <p><a href="#_ednref25" id="_edn25">[25]</a> David Shultz, „Divorce Rates Double When People Start Watching Porn“, <em>Science,</em> 26. augusta 2016, <a href="http://www.sciencemag.org/news">http://www.sciencemag.org/news</a> / 2016/08 / divorce-rates-double-when-people-start-watching-porn.</p>
        <p><a href="#_ednref26" id="_edn26">[26]</a> George Akerlof, et al., „An Analysis of Out-of-Wedlock Childbearing in the United States“, in: <em>Explorations of Pragmatic Economics</em> (New York: Oxford University Press, 2005), 120.</p>
        <p><a href="#_ednref27" id="_edn27">[27]</a> Joseph Chamie, „Out-of-Wedlock Births Rise Worldwide“, <em>YaleGlobal Online</em>, 16. marca 2017, <a href="https://yaleglobal.yale.edu/content/out-wedlock-births-rise-worldwide.">https://yaleglobal.yale.edu/content/out-wedlock-births-rise-worldwide.</a></p>
        <p><a href="#_ednref28" id="_edn28">[28]</a> Erik Hurst, „Video Killed the Radio Star,“ <em>Chicago Booth Review</em>, 1. septembra 2016, <a href="http://review.chicagobooth.edu/economics/2016/article/videokilled-radio-star.">http://review.chicagobooth.edu/economics/2016/article/videokilled-radio-star.</a></p>
        <p><a href="#_ednref29" id="_edn29">[29]</a> Mark Aguiar, Mark Bils, Kerwin Kofi Charles a Erik Hurst, „Leisure Luxuries and the Labor Supply of Young Men“, <em>The National Bureau of Economic Research</em>, Working Paper no. 23552 (jún 2017): 1, http: //www.nber.org/papers/w23552.</p>
        <p><a href="#_ednref30" id="_edn30">[30]</a> Tom Wijman, „Mobile Revenues Account for More Than 50% of the Global Games Market as It Reaches $ 137.9 Billion in 2018“, <em>Newzoo,</em> 30. apríla 2018, <a href="https://newzoo.com/insights/articles/global-gamesmarket-reaches-137-9-billion-in-2018-mobile-games-take-half">https://newzoo.com/insights/articles/global-gamesmarket-reaches-137-9-billion-in-2018-mobile-games-take-half</a> /.</p>
        <p><a href="#_ednref31" id="_edn31">[31]</a> „United States Crime Rates 1960-2018“, FBI UCS Annual Crime Reports, <em>DisasterCenter.com</em>, <a href="http://www.disastercenter.com/crime/uscrime.htm.">http://www.disastercenter.com/crime/uscrime.htm.</a></p>
        <p><a href="#_ednref32" id="_edn32">[32]</a> Bonnie Berkowitz a Chris Alcantara, „The Terrible Numbers That Grow with Each Mass Shooting", <em>The Washington Post</em>, aktualizované 4. marca 2019, stránka navštívená 29. apríla 2020, https: // www.washingtonpost.com/graphics/2018/national/mass-shootings-inamerica/?utm_term = .f63cc1b03c0b.</p>
        <p><a href="#_ednref33" id="_edn33">[33]</a> „Global Terrorism Database“, University of Maryland: National Consortium for the Study of Terrorism and Responses to Terrorism, stránka navštívená 29. apríla 2020, <a href="https://www.start.umd.edu/gtd/.">https://www.start.umd.edu/gtd/.</a></p>
        <p><a href="#_ednref34" id="_edn34">[34]</a> Jacque Wilson a William Hudson, „Gun Violence in PG-13 Movies Has Tripled“, <em>CNN,</em> 11. novembra 2013, <a href="http://www.cnn.com/2013/11/11/health/gun-violence-movies/index.html.">http://www.cnn.com/2013/11/11/health/gun-violence-movies/index.html.</a></p>
        <p><a href="#_ednref35" id="_edn35">[35]</a> Assil Frayh, „Gun Violence Keeps Rising in PG-13 Movies, <em>Study</em> <em>Says", CNN</em>, 20. januára 2017, <a href="https://www.cnn.com/2017/01/20/health/gun-violence-pg-13-movies-study">https://www.cnn.com/2017/01/20/health/gun-violence-pg-13-movies-study</a> / index.html.</p>
        <p><a href="#_ednref36" id="_edn36">[36]</a> „Violent Video Games and Young People“, <em>Harvard Mental Health Letter,</em> 27, č. 4 (október 2010), <a href="http://affectsofvideogames.weebly.com/uploads">http://affectsofvideogames.weebly.com/uploads</a> / 6/4/3/3/6433146 / medical_journal.pdf</p>
        <p><a href="#_ednref37" id="_edn37">[37]</a> Mary Garden, „Why I Bobbed My Hair“, <em>Pictorial Review</em>, apríl 1927, 8.</p>
        <p><a href="#_ednref38" id="_edn38">[38]</a> „Long Hair for Men“, <em>Encyclopedia of Fashion</em>, stránka navštívená 29. apríla 2020, <a href="http://www.fashionencyclopedia.com/fashion_costume_culture/Modern-World-Part-II-1961-1979/Long-Hair-for-Men.html.">http://www.fashionencyclopedia.com/fashion_costume_culture/Modern-World-Part-II-1961-1979/Long-Hair-for-Men.html.</a></p>
        <p><a href="#_ednref39" id="_edn39">[39]</a> „Hip Huggers“, <em>Encyclopedia of Fashion</em>, stránka navštívená 29. apríla 2020, <a href="http://www.fashionencyclopedia.com/fashion_costume_culture/Modern-World-Part-II-1961-1979">http://www.fashionencyclopedia.com/fashion_costume_culture/Modern-World-Part-II-1961-1979</a> / Hip-Huggers.html.</p>
        <p><a href="#_ednref40" id="_edn40">[40]</a> Kathryn Bromwich, „Groupies Revisited: The Women with Triple-A Access to the 60s", <em>The Guardian</em>, 15. novembra 2015, https: //www.theguardian.com/music/2015/nov/15/groupies-revisited-baron-wolman-rolling-stone-pamela-des-barres.</p>
        <p><a href="#_ednref41" id="_edn41">[41]</a> Neil Eriksen, „Popular Culture and Revolutionary Theory: Understanding Punk Rock“, <em>Theoretical Review</em> 18 (september-október 1980), navštívené prostredníctvom Marxistického internetového archívu 29. apríla 2020, <a href="https://www.marxists.org/history/erol/periodicals/theoretical-review">https://www.marxists.org/history/erol/periodicals/theoretical-review</a> /19801802.htm.</p>

      <Link to={`/kapitola_15`} className="learn__more-btn">
        <span className="hyphen"/>
        <span className="btn_text">ďalšia kapitola</span>
      </Link>
      <Link to={`/`} className="learn__more-btn">
        <span className="hyphen"/>
        <span className="btn_text">späť na hlavnú stránku</span>
      </Link>
    </ContentPageWrapper>
  );
};


